import axios from 'axios'
import { LOGINAPI_ADDRESS } from 'config'

interface UsersDataResponse {
  loginProgress: string
  verUser: boolean
}
/*
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
*/
// Get users progress to control different screen states
export const getUsersProgress = async (): Promise<UsersDataResponse> => {
  const data = localStorage.getItem('bba-token') ? JSON.parse(localStorage.getItem('bba-token')) : null
  return data
}

// User reset password call to api
export const usersReset = async (username: string): Promise<any> => {
  const url = `${LOGINAPI_ADDRESS}/api/users/request-reset-password`
  const { data } = await axios.post(url, {
    username,
  })
  return data
}

// Verfiy 2 factor code
export const usersVerifyCode = async (code: string, username: string): Promise<any> => {

  const url = `${LOGINAPI_ADDRESS}/api/users/verify-reset-code`
  const { data } = await axios.post(url, {
    code,
    username,
  })
  return data
}

// Resend 2 factor code
export const usersResendCode = async (username: string): Promise<any> => {
  const url = `${LOGINAPI_ADDRESS}/api/users/resend-code`
  const { data } = await axios.post(url, {
    username,
  })
  return data
}

// Update passwords
export const passwordUpdate = async (token: string, username: string, password: string): Promise<any> => {
  const url = `${LOGINAPI_ADDRESS}/api/users/reset-password`
  const { data } = await axios.post(url, {
    token,
    username,
    password,
  })
  return data
}
