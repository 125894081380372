import axios from 'axios'
import { API_ADDRESS } from 'config'

interface AccountDataResponse {
  ownerName: string
  ownerId: string
  access_token: string // eslint-disable-line
  username: string
  phonenumber: string
  userId: string
  role: string
  shortName: string
}

export const getAccountData = async (ownerName: string): Promise<AccountDataResponse> => {
  if (ownerName) {
    // ...
  }
  const data = localStorage.getItem('bba-token') ? JSON.parse(localStorage.getItem('bba-token')) : null

  //   const url = `${API_ADDRESS}/dashboard/account?name=${ownerName.toLocaleLowerCase()}`

  //   const { data } = await axios.get(url)

  return data
}

export const doLogin = async (username: string, password: string): Promise<any> => {
  const url = `${API_ADDRESS}/auth/login`
  const { data } = await axios.post(url, {
    username: username.toLowerCase(),
    password,
  })

  return data
}
