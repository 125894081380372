import axios from 'axios'
import { API_ADDRESS } from 'config'
import { decryptJSON } from 'utils/functions'

export const getListingData = async (_id: string): Promise<any> => {
  const url = `${API_ADDRESS}/dashboard/listing?id=${_id}`
  const { data } = await axios.get(url)
  try{
    return decryptJSON(data)
  }catch(err){
    return data
  }
}
