import axios from 'axios'
import { API_ADDRESS, SuburbsDataType } from 'config'
import type { Suburb } from 'state/types' // Owner, OwnerStats
import { decryptJSON } from 'utils/functions'

export const getOwnerData = async (ownerId: string) => {

  if ( ownerId === 'undefined' ){
    /*
		localStorage.clear()
		sessionStorage.clear()
    window.location.href = '/login'
    */
  }else{

    const url = `${API_ADDRESS}/dashboard/owner?ownerId=${ownerId}`
    const { data } = await axios.get(url)

    try{
      return decryptJSON(data)
    }catch(err){
      return data
    }
  }
  return []
}

export const getSuburbsData = async (ownerId: string, dataType: SuburbsDataType): Promise<Suburb[]> => {
  const url = `${API_ADDRESS}/dashboard/suburbs/data?ownerId=${ownerId}&dataType=${dataType}`
  const { data } = await axios.get(url)

  try{
    return decryptJSON(data)
  }catch(err){
    return data
  }
}

export const getSuburbsCsv = async (ownerId: string, dataType: SuburbsDataType): Promise<any[]> => {
  const url = `${API_ADDRESS}/dashboard/suburbs/csv?ownerId=${ownerId}&dataType=${dataType}`
  const { data } = await axios.get(url)

  try{
    return decryptJSON(data)
  }catch(err){
    return data
  }
}

export const getOwnerStats = async (ownerId: string) => {
  if ( ownerId === 'undefined' ){
    /*
		localStorage.clear()
		sessionStorage.clear()
    window.location.href = '/login'
    */
  }else{
    const url = `${API_ADDRESS}/dashboard/owner/stats?ownerId=${ownerId}`
    const { data } = await axios.get(url)
    try{
      return decryptJSON(data)
    }catch(err){
      return data
    }
  }
  return []
}
