import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Select, message } from 'antd'
// import { Menu, Menu as AntMenu } from 'antd'
// import { FaBed, FaHome, FaMap, FaMapMarkerAlt } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
// import { Link, NavLink, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { updateAccountData } from 'state/account'
import { useFetchAccount } from 'state/account/hooks'
// import { useAccount, useFetchAccount } from 'state/account/hooks'
import { fetchUsersAccessList } from 'state/users-access'
import { useAllAccess } from 'state/users-access/hooks'
import { useUsers } from 'state/users/hooks'
// import { IconModule } from '../Icons'
// import links from './config'
/*
interface IconProps {
  size: string
}
*/
// const Icons = IconModule as unknown as { [key: string]: React.FC<IconProps> }

const MenuItems = () => {

  // const [ councilId, setCouncilId ] = useState(localStorage.getItem('bbg-owner'))
  const { accessList } = useAllAccess()
  // const { accessList, isLoading } = useAllAccess()
  const [ confirmModalVisible, setConfirmModalVisible ] = useState(false)
  const [ accountModalVisible, setAccountModalVisible ] = useState(false)
  const dispatch = useAppDispatch()
  const [accountName, setAccountName] = useState(null)
  // const { ownerName, shortName, isAuthenticated } = useAccount()
  const { councilsAcList, isAdmin } = useUsers()
  // const { councilsAcList, pic, isAdmin } = useUsers()
  // const AVATAR_LOCATION = '/images/avatar.png'
  // const [dataSource, setDataSource] = useState(accessList)
 
  useFetchAccount(accountName)

  useEffect(() => {
    dispatch(fetchUsersAccessList())
  }, [dispatch])

  let isMultiple = false
  // const items = [...accessList]

  //   const [value, setValue] = useState('')
  //   const [options, setOptions] = useState<{ value: string }[]>([])

  if (councilsAcList.length > 1) {
    isMultiple = true
  }

  /* Admin User Login */
  const selectChange = useCallback(
    (value) => {
      const values = value.split('|')
      const ownerName = values[0]
      const ownerState = values[1]
      const listings = values[2]
      const properties = values[3]
      if(listings < 1 && properties < 1){
        message.error([`There are no listings or properties in ${ownerName?.toUpperCase()}, ${ownerState?.toUpperCase()}.`,<br />, <br />, 'Please select a different council.'])
        return false
      }
      const result = (accessList as any).filter((obj) => obj.ownerName === ownerName && obj.state === ownerState )
      dispatch(updateAccountData(result[0].ownerName, result[0]._id))
      setAccountName(result[0].ownerName)
      window.location.href = '/' // reload()
      return true
    },
    [dispatch, accessList],
  )

  /* Council User Login */
  const handleChange = useCallback(
    (value) => {
      const result = councilsAcList.filter((obj) => obj.ownerId === value)
      dispatch(updateAccountData(result[0].ownerName, result[0].ownerId))
      setAccountName(result[0].ownerName)
      window.location.href = '/' // reload()
    },
    [dispatch, councilsAcList],
  )
  const onTextSearch = useCallback(() => {
  // const onTextSearch = useCallback((value) => {
    // const result = accessList.filter((obj) => obj.ownerName === value)
    //   dispatch(updateAccountData(result[0].ownerName, result[0].ownerId))
    // setAccountName(result[0].ownerName)
  }, [])

  const location = useLocation()
  const iconSize = '1.7em'
  const links = [
    {
      id: 1,
      path: '/',
      name: '', // 'Dashboard',
      icon: 'FaHome',
      // fa: <svg viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V9.48907C3 9.18048 3.14247 8.88917 3.38606 8.69972L11.3861 2.47749C11.7472 2.19663 12.2528 2.19663 12.6139 2.47749L20.6139 8.69972C20.8575 8.88917 21 9.18048 21 9.48907V20ZM19 19V9.97815L12 4.53371L5 9.97815V19H19Z"/></svg>, // <FaHome />,
      fa: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" stroke="currentColor" fill="currentColor" width="1em" height="1em" ><path d="M288 5.5l5.2 4.5 280 240-10.4 12.1L512 218.5V504v8h-8H72 64v-8V218.5L13.2 262.1 2.8 249.9l280-240L288 5.5zm0 21.1L80 204.8V496H208V312v-8h8H360h8v8V496H496V204.8L288 26.5zM224 496H352V320H224V496z"/></svg>,
      iconSize,
    },
    {
      id: 2,
      path: '/map',
      name: 'Map',
      icon: 'FaMap', // MarkerAlt',
      // fa: <FaMap />,
      // fa: <svg viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M2 5L9 2L15 5L21.303 2.2987C21.5569 2.18992 21.8508 2.30749 21.9596 2.56131C21.9862 2.62355 22 2.69056 22 2.75827V19L15 22L9 19L2.69696 21.7013C2.44314 21.8101 2.14921 21.6925 2.04043 21.4387C2.01375 21.3765 2 21.3094 2 21.2417V5ZM14.9352 7.20369L8.93524 4.20369L4 6.31879V18.9669L9.06476 16.7963L15.0648 19.7963L20 17.6812V5.03308L14.9352 7.20369Z"/></svg>,
      fa: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor" width="1em" height="1em" ><path d="M181.3 32.5c1.6-.6 3.4-.6 5.1-.1L391.8 95.6 533.1 44.2c20.9-7.6 42.9 7.9 42.9 30.1V391.2c0 13.5-8.4 25.5-21.1 30.1L394.7 479.5c-1.6 .6-3.4 .6-5.1 .1L184.2 416.4 42.9 467.8C22.1 475.4 0 459.9 0 437.7V120.8c0-13.5 8.4-25.5 21.1-30.1L181.3 32.5zM26.5 105.8c-6.3 2.3-10.5 8.3-10.5 15V437.7c0 11.1 11 18.8 21.5 15L176 402.4V51.4L26.5 105.8zM384 461.2V109.9L192 50.8V402.1l192 59.1zm16-.6l149.5-54.4c6.3-2.3 10.5-8.3 10.5-15V74.3c0-11.1-11-18.8-21.5-15L400 109.6v351z"/></svg>,
      iconSize,
    },
    {
      id: 3,
      path: '/listings',
      name: 'Listings',
      icon: 'FaBed',
      // fa: <FaBed />,
      // fa: <svg viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M22 11V20H20V17H4V20H2V4H4V14H12V7H18C20.2091 7 22 8.79086 22 11ZM20 14V11C20 9.89543 19.1046 9 18 9H14V14H20ZM8 11C8.55228 11 9 10.5523 9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11ZM8 13C6.34315 13 5 11.6569 5 10C5 8.34315 6.34315 7 8 7C9.65685 7 11 8.34315 11 10C11 11.6569 9.65685 13 8 13Z"/></svg>,
      fa: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" stroke="currentColor" fill="currentColor" width="1em" height="1em" ><path d="M16 40V32H0v8V328v80 64 8H16v-8V416H624v56 8h16v-8V408 328 232c0-57.4-46.6-104-104-104H280h-8v8V320H16V40zM624 400H16V336H280 624v64zm0-168v88H288V144H536c48.6 0 88 39.4 88 88zM80 208a64 64 0 1 1 128 0A64 64 0 1 1 80 208zm144 0A80 80 0 1 0 64 208a80 80 0 1 0 160 0z"/></svg>,
      iconSize,
    },
    {
      id: 4,
      path: '/properties',
      name: 'Properties',
      icon: 'FaMapMarkerAlt', // 'FaSearch',
      // fa: <FaMapMarkerAlt />,
      // fa: <svg viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 20.8995L16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13ZM12 15C9.79086 15 8 13.2091 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15Z"/></svg>,
      fa: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" stroke="currentColor" fill="currentColor" width="1em" height="1em" ><path d="M368 192c0-97.2-78.8-176-176-176S16 94.8 16 192c0 18.7 6.4 42.5 17.8 69.6c11.3 26.9 27.1 55.8 44.7 84.3c35.2 57 76.8 111.4 102.3 143.2c5.9 7.3 16.6 7.3 22.4 0c25.5-31.8 67.1-86.2 102.3-143.2c17.6-28.5 33.4-57.4 44.7-84.3C361.6 234.5 368 210.7 368 192zm16 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zM192 112a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm64 80a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>,
      iconSize,
    },
  ]

  const logout = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href='/login'  
  }

  return (
    <>

      <Modal
            title="Select Council"
            visible={accountModalVisible}
            destroyOnClose
            // footer={null}
            okText='Close'
            onOk={() => {setAccountModalVisible(false);}}
            cancelButtonProps={{ style: { display: 'none' } }}
            // onCancel={() => {setAccountModalVisible(false);}}
            // cancelText='Cancel'
            style={{maxWidth:'360px', borderRadius:'5px', overflow:'hidden', padding: '0px'}}
        >
          {!isAdmin && isMultiple === true && (

            <Select
              placeholder="Select Council"
              onChange={handleChange}
              className="switch-account-select"
              >
              {(councilsAcList as any).map((option) => {
                return (
                  <Select.Option key={option.ownerId} value={option.ownerId}>
                    {`${option?.ownerName?.toUpperCase()}`}
                  </Select.Option>
                )
              })}
            </Select>

          )}

          {isAdmin && (

            <Select
              showSearch
              placeholder="Select Council"
              optionFilterProp="children"
              className="switch-account-select"
              onChange={selectChange}
              onSearch={onTextSearch}
              // filterOption={(input, option) =>
              //   (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              // }
            >
              {(accessList as any).map((option) => {
                return (
                <Select.Option key={option._id} value={`${option?.ownerName}|${option?.state}|${option?.listings}|${option?.properties}`}>
                  {`${option?.ownerName?.toUpperCase()}, ${option?.state?.toUpperCase()} (${option?.listings}, ${option?.properties})`}
                </Select.Option>
                )
              })}
            </Select>

          )}
      </Modal>

      <Modal
            title="Confirm"
            visible={confirmModalVisible}
            destroyOnClose
            // footer={null}
            okText='Log out'
            onOk={logout}
            // cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => {setConfirmModalVisible(false);}}
            cancelText='Cancel'
            style={{maxWidth:'300px', borderRadius:'5px', overflow:'hidden', padding: '0px'}}
        >
          Logout?
      </Modal>

      <header className="global-header">
        <div className="global-header-logo-holder">
          { /* <img src="../images/bnb_guard_rev.png" alt="BNBGuard Logo" className="global-header-logo-desktop" /> */ }
          <img src="../images/bnb_guard_shield.png" alt="BNBGuard Logo" className="global-header-logo-desktop" />
          <img src="../images/bnb_guard_shield.png" alt="BNBGuard Logo" className="global-header-logo-mobile" />
        </div>
      </header>

    <div className="bnbguard-menu">
      {links?.map( link => {
        return (
          <div className={link.path === location.pathname ? 'bnbguard-menu-item-active' : 'bnbguard-menu-item'} key={`${link.path.replace(/\//g,'_')}`} style={{fontSize: link.iconSize}}>
              <Link to={link.path} className={link.path === location.pathname ? 'bnbguard-menu-item-active' : 'bnbguard-menu-item'} style={{fontSize: link.iconSize}}>
                {link.fa}
                <small>{link.name}</small>
              </Link>
          </div>
        )
      })}
      { /* Switch Account */ }
      {isAdmin && (
          <div className='bnbguard-menu-item' key='menu_item_switch_account' style={{fontSize: iconSize, marginTop:'50px'}}>
              <button
                 type="button" className='bnbguard-menu-item'
                 style={{fontSize: iconSize, border:'none', padding: '0px', margin: '0px', cursor:'pointer'}}
                 onClick={() => setAccountModalVisible(true)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" stroke="currentColor" fill="currentColor" width="1em" height="1em" ><path d="M345.4 16l80 80-80 80H336V104 88 16h9.4zM320 16V88H8 0v8V264v8H16v-8V104H320v72 16h16 16l84.7-84.7L448 96 436.7 84.7 352 0H336 320V16zM166.6 496l-80-80 80-80H176v72 16 72h-9.4zm25.4 0V424H504h8v-8V248v-8H496v8V408H192V336 320H176 160L75.3 404.7 64 416l11.3 11.3L160 512h16 16V496z"/></svg>
              </button>
          </div>
      )}
      </div>
      { /* Logout */ }
      <div className='bnbguard-menu-item' key='menu_item_logout' style={{fontSize: iconSize, marginTop:'10px'}}>
              <button
                 type="button" className='bnbguard-menu-item'
                 style={{fontSize: iconSize, border:'none', padding: '0px', margin: '0px', cursor:'pointer'}}
                 onClick={() => setConfirmModalVisible(true)}
              >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" stroke="currentColor" fill="currentColor" width="1em" height="1em" ><path d="M501.7 261.7l5.7-5.7-5.7-5.7-136-136-5.7-5.7L348.7 120l5.7 5.7L476.7 248 168 248l-8 0 0 16 8 0 308.7 0L354.3 386.3l-5.7 5.7L360 403.3l5.7-5.7 136-136zM184 48l8 0 0-16-8 0L8 32 0 32l0 8L0 472l0 8 8 0 176 0 8 0 0-16-8 0L16 464 16 48l168 0z"/></svg>
              </button>
      </div>
    </>
  )

}

/*
const MenuItems = () => {
  const location = useLocation()
  return (
    <AntMenu
      mode="inline"
      selectedKeys={links.filter((link) => link.path === location.pathname).map((link) => link.id.toString())}
      style={{ height: '100%', borderRight: 0}}
      theme="dark"
    >
      {links.map((entry) => {
        const Icon = Icons[entry.icon]
        return (
          <Menu.Item
            key={entry.id}
            icon={<Icon size={entry.iconSize} />}
            title={entry.name}
            style={{ paddingLeft: 0, paddingRight: 0, fontSize: 15, display: 'inline-flex', alignItems: 'center' }}
          >
            <NavLink exact to={entry.path} style={{ marginLeft: 0 }}>
              {entry.name}
            </NavLink>
          </Menu.Item>
        )
      })}
    </AntMenu>
  )
}
*/
export default MenuItems
